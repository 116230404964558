:root {
    /* Cores */
    --red: #e0342e;
    --shadow-red: #E2413C;
    --white: #FCFCFC;
    --gray: #f0f0f0;
    --black: #2E2922;
    --fs-description: 20px;

    --red-text: #af0e0e;
    --black-text: #2E2922;
    --white-text: #FCFCFC;

    /* Fontes */
    --h1-font: 'Oswald', sans-serif;
    --h2-font: 'PT Sans', sans-serif;
    --h3-font: 'PT Sans', sans-serif;
    --p-font: 'PT Sans', sans-serif;

    /* Tipografia*/
    --h1-banner: 56px;
    --h1: 44px;
    --h2: 33px;
    --h3: 22px;
    --p: 19px;
    --mobile-h1: 30px;
    --mobile-h2: 27px;
    --mobile-h3: 22px;
    --mobile-p: 18px;
    --h1-line-height: calc(var(--h1) * 1.2);
    --h2-line-height: calc(var(--h2) * 1.5);
    --h3-line-height: calc(var(--h3) * 1.5);
    --p-line-height: calc(var(--p) * 1.5);

    /* Botões*/
    --normal-btn: 44px;
    --large-btn: 54px;
    --extra-large-btn: 64px;
    --border-radius-normal-btn: 5px;
    --border-radius-large-btn: 10px;
    --border-radius-extra-large-btn: 15px;

    /* Sombras */
    --shadow-input: 1px 1px 2px var(--gray);
    --shadow-card: 2px 2px 2px var(--gray);
}

.banner {
    height: calc(100vh - 80px);
    background-color:var(--gray);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../assets/Charity.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.banner .btn-primary {
    background-color: var(--red);
    color: var(--white);
    border-radius: 0;
    height: var(--extra-large-btn);
    margin-top: 35px;
    /* border-radius: var(--border-radius-extra-large-btn); */
    font-size: var(--h3);
    font-family: var(--h3-font);
    border: 1px solid var(--red);
    cursor: pointer;
    width: 280px;
    transition: background-color .3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-primary:hover {
    background-color: var(--shadow-red);
}

.about-section{
    width: 100%;
    padding: 1rem;
}

.about-section__img {
    max-width: 100%;
    height: auto;
    filter: drop-shadow(4px 4px 4px #a0a0a0);
}

/* STARTS TIPOGRAPHY */

.h1 {
    font-size: var(--h1);
    line-height: var(--h1-line-height);
    text-align: center;
    font-family: var(--h1-font);
    margin-bottom: 32px;
}

.h1-banner{
    font-size: var(--mobile-h1);
    text-align: center;
} 

.red-text{
    color: var(--red-text);
}

.white-text{
    color: var(--white-text);
}
.black-text {
    color: var(--black-text);
}

.h2 {
    font-size: var(--h2);
    line-height: var(--h2-line-height);
    text-align: center;
    font-family: var(--h2-font);
}

.p {
    font-size: var(--p);
    line-height: var(--p-line-height);
    font-family: var(--p-font);
    text-align: start;
    margin-bottom: 16px;
}

.center-text{
    text-align: center;
}

/* ENDS TIPOGRAPHY */

/* START OUR-ROLE-SECTION */
.our-role-section{
    width: 100%;
    padding: 1rem;
}


/* END OUR-ROLE-SECTION */

/* start CAUSE SECTION */
.causes-section {
    background-color: var(--gray);
    padding: 1rem;
}

.causes .causes_title{
    text-align: center;
}

.cause-card{
    /*background-color: var(--red);*/
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 2px 2px 2px #a0a0a0;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
}

.cause-card__img{
    max-width: 100%;
    height: auto;
}

.cause-card__body {
    padding: 10px;
    color: var(--white-text);
    background-color: var(--red);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* end CAUSE SECTION */

/* start VISION, VALUES AND PURPOSE */
.vision {
    background-color: var(--red);
    color: var(--white);
    padding: 2rem 1rem;
}    

.purpose {
    background-color: var(--white);
    color: var(--red);
    padding: 2rem 1rem;
}


.values {
    background-color: var(--red);
    color: var(--white);
    padding: 2rem 1rem;
}

.value-card {
    border: 1px solid var(--white);
    border-radius: 30px;
    box-shadow: var(--shadow-card);
    /*width: 80% !important;*/
    color: var(--red);
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-bottom: 16px;
    overflow: hidden;
    padding: 0 16px 16px 16px;
    
}

.value-card > .h2{
    width: 100%;
    align-self: center;
}

.value-card__card_body {
    flex-grow: 1;
    width: 100%;
    margin-bottom: 0;  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.value-card__card_body > img {
    max-width: 100%; 
    margin-bottom: .7rem;
}

.value-card__card_body > .p {
    margin: 0;
}

.value-card__rounded-img{
    width: 180px;
    margin: 16px 0 10px 0;
}


/* end VISION, VALUES AND PURPOSE */


.indicators-section {
    padding: 1rem;
}

.indicators .indicators_title{
    text-align: center;
}

.contact-section {
    background-color: var(--gray);
    padding: 1rem;
}

.contact .contact_title{
    text-align: center;
}

@media only screen and (min-width:320px) { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ }

@media only screen and (min-width:480px) { 
    /* smartphones, Android phones, landscape iPhone */
    .h1-banner{
        width: 75%
    } 
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

    .values-container {
        width: 95%;
        margin: 0 auto;
    }
    
    .values-container img{
        display: none;
    }

    /* START CAUSES SECTION */
    .cause-card__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    }

    .cause-card {
        display: flex;
        flex-direction: column;
    }

    .cause-card__body{
        flex-grow: 1;
    }

    /* END CAUSES SECTION */
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .h1-banner{
        font-size: var(--h1-banner);
        text-align: center;
        width: 85%
    } 

    .about-section{
        width: 85%;
        margin: 0 auto;
    }

    .our-role-section {
        width: 85%;
        margin: 0 auto;
    }

    .value-card {
        width: 85%;
        margin: 0 auto 16px auto;
    }

    .values-container {
        width: 100%;
        margin: 0 auto;
    }

    /*
    .about-section__img {
        max-width: 50%;
        height: auto;
        float: left;
        padding: .6rem 0.8rem 0 0;
    }
    */

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .h1-banner{
        font-size: var(--h1-banner);
        text-align: center;
        width: 65%;
    } 
    .about-section{
        width: 80%;
    }

    .vision .p{
        width: 80%;
        margin: 0 auto;
    }    
    
    .purpose .p{
        width: 80%;
        margin: 0 auto;
    }

    .values-container {
        width: 85%;
        max-width: 800px;
        margin: 0 auto;
    }
    
    
    .values .p {
        width: 80%;
        margin: 0 auto;
    }

    .our-role-section > .section__content{
        width: 80%;
        margin: 0 auto;
    }

    .causes-section > .section__content{
        width: 80%;
        margin: 0 auto;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
    .about-section{
        width: 60%;
        margin: 0 auto;
    }

    .vision .p{
        width: 60%;
    }    
    
    .purpose .p{
        width: 60%;
    }
    
    
    .values .p {
        width: 60%;
    }

    .our-role-section .section__content {
        width: 60%;
        margin: 0 auto;
    }

    .section__content {
        width: 60%;
        margin: 0 auto;
    }

    .indicators-section {
        width: 60%;
        margin: 0 auto;
    }
}   

